jQuery(document).foundation();

jQuery(function() {
	
	jQuery('input.datumsfeld').Zebra_DatePicker({
		direction: true,
		days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Sonnabend'],
		default_position: 'below',
		format: 'd.m.Y',
		lang_clear_date: 'Zurücksetzen',
		months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
		readonly_element: true,
		show_select_today: 'Heute',
		show_week_number: 'KW'
	});
	

	var idLand = parseInt(jQuery('select#idLand').find('option:selected').val());
	if(idLand === 0)
	{
		jQuery('select#idBundesland option:gt(0), select#idRegion option:gt(0)').remove();
		jQuery('select#idBundesland, select#idRegion').prop('disabled', 1);
	}
	jQuery('select.landAuswahl').change(function()
	{
		var idLand = parseInt(jQuery(this).find('option:selected').val());
		
		if(idLand > 0)
		{
			jQuery.ajax(
				'/ajax/bundeslaender/' + idLand,
				{
					success: function(data)
					{
						jQuery('select#idBundesland option:gt(0), select#idRegion option:gt(0)').remove();
						if(data.length > 0)
						{
							jQuery(data).each(function(key, index)
							{
								jQuery('select#idBundesland option:last').after(jQuery('<option />').val(index.idBundesland).text(index.nameBundesland));
							});
							jQuery('select#idBundesland').prop('disabled', 0);						
						}
						else
						{
							jQuery('select#idBundesland, select#idRegion').prop('disabled', 1);	
						}
					},
					dataType: 'json'
				}
			);
		}
		else
		{
			jQuery('select#idBundesland option:gt(0), select#idRegion option:gt(0)').remove();
			jQuery('select#idBundesland, select#idRegion').prop('disabled', 1);
		}
	});
	

	
	
	jQuery('select.bundeslandAuswahl').change(function()
	{
		var idBundesland = parseInt(jQuery(this).find('option:selected').val());
		if(idBundesland > 0)
		{
			jQuery.ajax(
				'/ajax/regionen/' + idBundesland,
				{
					success: function(data)
					{
						jQuery('select#idRegion option:gt(0)').remove();
						if(data.length > 0)
						{
							jQuery(data).each(function(key, index)
							{
								jQuery('select#idRegion option:last').after(jQuery('<option />').val(index.idRegion).text(index.nameRegion));
							});
							jQuery('select#idRegion').prop('disabled', 0);						
						}
						else
						{
							jQuery('select#idRegion').prop('disabled', 1);	
						}
					},
					dataType: 'json'
				}
			);
		}
		else
		{
			jQuery('select#idRegion option:gt(0)').remove();
			jQuery('select#idRegion').prop('disabled', 1);
		}
	});	
	
	jQuery(".multiselect").chosen({no_results_text: 'Kein Treffer!', width: '100%'});
	
	jQuery("#stellenanzeigeAktivInaktiv").on("click",function() { 
		
		titel = jQuery(this).data("titel");
		aktiv = jQuery(this).data("aktiv")*1;
		jQuery('#aktivInaktivFrage .lead').html(titel + " - "+ aktiv);
		if(aktiv==1) 
		{ 
			jQuery('#aktivSchalten').html("Deaktivieren"); 
		} 
		else 
		{
			jQuery('#aktivSchalten').html("Aktivieren"); 
		}
	});	
	
	// FAQ - Scroll einfach
	// <a href="#anker" class="faq">
	jQuery('.faq').on('click', function(e){
	  var href = $(this).attr('href');
	  jQuery('html, body').animate({
		scrollTop:$(href).offset().top
	  },'slow');
	  e.preventDefault();
	});
	
	// nach oben
	jQuery('.top').on('click', function(e){
	  jQuery('html, body').animate({
		scrollTop:$('body').offset().top
	  },'slow');
	  e.preventDefault();
	});

	jQuery('a.zurueck').click(function(e)
	{
		e.preventDefault();
		window.history.back();
		return false;
	});
	
	
	// Cookie-Hinweis
	window.addEventListener("load", function(){
		window.cookieconsent.initialise({
		  "palette": {
			"popup": {
			  "background": "#2362AF",
			  "text": "#ffffff"
			},
			"button": {
			  "background": "#2362AF",
			  "text": "#ffffff"
			}
		  },  
		  "cookie": {  
		  "expiryDays": 7,
			"domain": "www.handwerker.de"
		  },
		  //"showLink": false,
		  "theme": "edgeless",
		  "content": {
			"message": 'Wir verwenden Cookies, um Funktionalitäten der Website zu ermöglichen. Durch die weitere Nutzung unserer Website erklären Sie sich mit der Verwendung von Cookies einverstanden. Weitere Informationen erhalten Sie auf der Seite ',
			"dismiss": "Hinweis schließen",
			"link": "Datenschutz",
			"href": "/ueber-uns/datenschutz"
		  }
		});
	});	
	
	
});